<template>
  <div>
    <div class="wrap pb-16">
      <page-info
        name="Portfele"
        description="Portfele uprawnione do głosowania"
      />

      <buttons-menu />

      <div class="my-3">
        <div>
          <v-card
            class="mt-3"
            v-for="(percent, index) in participantsAdresses"
            :key="index + '_' + percent"
            elevation="2"
          >
            <v-card-text>
              <div class="d-flex align-center">
                <Avatar
                  :name="participantsAdresses[index].toString()"
                  :size="40"
                />
                <div class="ml-5">
                  {{ $shortAddress(participantsAdresses[index], windowWidth) }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ButtonsMenu from "../components/buttonsMenu";
  import PageInfo from "../components/pageInfo";
  import Avatar from "../components/avatar";

  export default {
    components: {
      PageInfo,
      ButtonsMenu,
      Avatar,
    },

    data() {
      return {
        participantsAdresses: [],
        windowWidth: window.innerWidth,
      };
    },

    watch: {
      blockchainConnected(data) {
        if (data) {
          this.getParticipants();
        }
      },
    },

    computed: {
      blockchainConnected() {
        return this.$store.state.web3.active;
      },
    },

    methods: {
      handleResize() {
        this.windowWidth = window.innerWidth;
      },

      async getParticipants() {
        const info = await this.$web3Call("percentagePayouts");
        this.participantsAdresses = info.participantsAdresses;
      },
    },

    destroyed() {
      window.removeEventListener("resize", this.handleResize);
    },

    mounted() {
      window.addEventListener("resize", this.handleResize);
      if (this.blockchainConnected) {
        this.getParticipants();
      }
    },
  };
</script>
